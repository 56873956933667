body {
  margin: 0;
  font-family: "Inter", sans-serif;
  color: var(--baseColor);
  background: var(--baseBackground);
  overflow-x: hidden;
}

:root {
  --lime-green: hsl(163, 72%, 41%);
  --bright-red: hsl(356, 69%, 56%);

  --facebook: #168ef4;
  --twitter: hsl(203, 89%, 53%);
  --instagram-1: hsl(37, 97%, 70%);
  --instagram-2: hsl(329, 70%, 58%);
  --youTube: hsl(348, 97%, 39%);
  --gray: #9b9ea7;
  --cardGray: #f0f3fa;
  --lightGray: #f8f9fe;
  --cardTitle: #f0f3fa;
  --baseBackground: white;
  --baseColor: #1d2029;
  --switchSpinner: white;
  --switch: #5f616d;
}

.wrapper {
  padding: 0 30px;
}

.grid {
  display: grid;
  grid-row-gap: 30px;
}

@media screen and (min-width: 480px) {
  .grid {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
  }
  .wrapper {
    max-width: 1440px;
    margin: auto;
  }
}

@media screen and (min-width: 768px) {
  .grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 1024px) {
  .grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .header-total {
    border: none;
  }
  .header-grid {
    display: grid;
    grid-template-columns: 1fr 200px;
  }
}

.is-dark-mode {
  --cardGray: #252b43;
  --baseBackground: #1d2029;
  --baseColor: white;
  --gray: #8088ad;
  --lightGray: #1d2029;
  --cardTitle: #8088ad;
  --switchSpinner: #282943;
  --switch: linear-gradient(to right, #4796d2 0%, #37cebf 100%);
}

.is-light-mode {
  --gray: #9b9ea7;
  --cardGray: #f0f3fa;
  --lightGray: #f8f9fe;
  --cardTitle: #575a66;
  --baseBackground: white;
  --baseColor: #1d2029;
  --switchSpinner: white;
  --switch: #5f616d;
}

@media (prefers-color-scheme: dark) {
  :root {
    --cardGray: #252b43;
    --baseBackground: #1d2029;
    --baseColor: white;
    --gray: #8088ad;
    --lightGray: #1d2029;
    --cardTitle: #8088ad;
    --switchSpinner: #282943;
    --switch: linear-gradient(to right, #4796d2 0%, #37cebf 100%);
  }
}

h2 {
  color: var(--gray);
}

/* Slim Scrollbar Styles */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--baseBackground);
}

::-webkit-scrollbar-thumb {
  background-color: var(--gray);
  border-radius: 10px;
  border: 2px solid var(--baseBackground);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--baseColor);
}
