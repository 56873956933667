/* switch.css */

.dark-mode {
  display: flex;
  align-items: center;
  gap: 0.5em; /* Space between icons and switch */
}

.icon {
  width: 24px;
  height: 24px;
}

.sun-icon {
  color: #f9d71c; /* Sun color */
}

.moon-icon {
  color: #1e1e1e; /* Moon color */
}

.checkbox {
  display: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px; /* Shorter width */
  height: 25px; /* Shorter height */
  border-radius: 15px;
  background: var(--switch);
  cursor: pointer;
}

.switch::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
  background: var(--switchSpinner);
  border-radius: 50%;
  transition: transform 0.3s;
}

.checkbox:checked + .switch::before {
  transform: translateX(25px);
}

/* switch.css */
.theme-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icon {
  width: 24px;
  height: 24px;
  color: var(--icon-color); /* Adjust icon color as needed */
  transition: color 0.3s;
}

/* Additional styles for icon colors based on theme */
.is-light-mode .icon {
  color: #1e1e1e; /* Dark color for light mode */
}

.is-dark-mode .icon {
  color: #f9d71c; /* Light color for dark mode */
}
