/*header*/

.header {
  /* border: 1px solid red; */
  background: var(--lightGray);
  padding-bottom: 100px;
  border-radius: 0 0 20px 20px;
  padding-top: 2em;
  /* display: none; */
}
.header-total {
  color: var(--gray);
  margin: 0.5em 0;
  font-weight: 600;
  border-bottom: 1px solid var(--gray);
  padding-bottom: 1.5em;
}
.header-title {
  margin: 0;
  font-size: 1.8em;
}

.header-grid {
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
}

/* header.css */
.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 1rem;
  transition: background 0.3s ease;
}

.dark-theme {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title h1 {
  margin: 0;
  font-size: 1.5rem;
}

.header-subtitle {
  margin: 0;
  font-size: 1rem;
  color: #666;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.sign-in-button {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
}

.sign-in-button:hover {
  background: #fff;
  color: #000;
}

.theme-switch {
  transform: scale(0.8); /* Make the switch shorter */
}

/* Mobile Styles */
@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-title {
    margin-bottom: 1rem;
  }

  .header-actions {
    width: 100%;
    justify-content: space-between;
  }
}

/* header.css */
.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 1rem;
  transition: background 0.3s ease;
}

.dark-theme {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title h1 {
  margin: 0;
  font-size: 1.5rem;
}

.header-subtitle {
  margin: 0;
  font-size: 1rem;
  color: #666;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.sign-in-button {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
}

.sign-in-button:hover {
  background: #fff;
  color: #000;
}

.light-theme .sign-in-button {
  border-color: #000;
  color: #000;
}

.light-theme .sign-in-button:hover {
  background: #000;
  color: #fff;
}

.inbox-container {
  margin-bottom: 1rem; /* Add space at the bottom */
}

/* Mobile Styles */
@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-title {
    margin-bottom: 1rem;
  }

  .header-actions {
    width: 100%;
    justify-content: space-between;
  }
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 1rem;
  transition: background 0.3s ease;
}

.dark-theme {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title h1 {
  margin: 0;
  font-size: 1.5rem;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.sign-in-button {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
}

.sign-in-button:hover {
  background: #fff;
  color: #000;
}

.light-theme .sign-in-button {
  border-color: #000;
  color: #000;
}

.light-theme .sign-in-button:hover {
  background: #000;
  color: #fff;
}

.inbox-container {
  margin-bottom: 1rem; /* Add space at the bottom */
}

/* Mobile Styles */
@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-title {
    margin-bottom: 1rem;
  }

  .header-actions {
    width: 100%;
    justify-content: space-between;
  }
}
