.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16vh;
  background: var(--background-color);
  color: var(--text-color);
  transition: background 0.3s ease, color 0.3s ease;
}

.hero-content {
  max-width: 70%;
  text-align: center;
  margin-bottom: 30px;
}

.hero-content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.hero-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.hero-button:hover {
  background-color: var(--button-hover-background-color);
}

.hero-image-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.hero-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Light Theme */
.light-theme {
  --background-color: #f9f9f9;
  --text-color: #333;
  --button-background-color: #007bff;
  --button-text-color: #fff;
  --button-hover-background-color: #0056b3;
}

/* Dark Theme */
.dark-theme {
  --background-color: #333;
  --text-color: #f9f9f9;
  --button-background-color: #1a73e8;
  --button-text-color: #fff;
  --button-hover-background-color: #1558b3;
}
